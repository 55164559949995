import React, { ReactNode } from "react"
import { CSSObject } from "@emotion/react"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import { observer } from "mobx-react-lite"
import { semanticSpacing, unitSpacing } from "../../theme"
import { mediaQueries } from "pitch45-common/theme"
import Loop45Logo from "../logo/logo"

interface TwoColumnContainerProps {
  contentTheme?: AppTheme
  backgroundImageSrc?: string
  leftContent: ReactNode
  rightContent: ReactNode
}

function getStyles(backgroundImageSrc?: string) {
  const styles = {
    TWO_COLUMN_CONTAINER: {
      padding: semanticSpacing.horizontal.narrow,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      height: "100%",
      width: "100%",
      backgroundImage: `url(${backgroundImageSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      [mediaQueries.TABLET]: {
        gridTemplateColumns: "1fr",
        minHeight: "100vh",
        minWidth: "100vw",
      },
    },
    RIGHT_CONTAINER: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      height: "100%",
      [mediaQueries.TABLET]: {
        display: "none",
      },
    },
    LEFT_CONTAINER: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      height: "100%",
      paddingRight: unitSpacing.quadPlus,
      [mediaQueries.TABLET]: {
        paddingRight: 0,
        alignItems: "center",
      },
    },
    LOGO: {
      position: "absolute",
      top: unitSpacing.doubleQuarter,
      left: unitSpacing.double,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

const TwoColumnContainer = observer(function TwoColumnContainer({
  leftContent,
  rightContent,
  backgroundImageSrc,
}: TwoColumnContainerProps) {
  const styles = getStyles(backgroundImageSrc)
  return (
    <div css={styles.TWO_COLUMN_CONTAINER}>
      <div css={styles.LOGO}>
        <Loop45Logo size="medium" />
      </div>
      <div css={styles.LEFT_CONTAINER}>{leftContent}</div>
      <div css={styles.RIGHT_CONTAINER}>{rightContent}</div>
    </div>
  )
})

export default TwoColumnContainer
