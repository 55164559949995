import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { CSSObject } from "@emotion/react"
import validate from "validate.js"
import { TextInput, AsyncButton, RadioInput, Text, textPresets } from "../../ui"
import { unitSpacing } from "../../theme/spacing"
import { color } from "pitch45-common/theme"
import Link from "next/link"

function getStyles() {
  const styles = {
    LOGIN_FORM_BODY: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: unitSpacing.unit,
      minWidth: "300px",
    },
    LOGIN_INPUT: {
      marginBottom: unitSpacing.unit,
      width: "100%",
      "& .MuiInputBase-root": {
        backgroundColor: color.backgroundHighlight,
        padding: 0,
      },
    },
    TERMS_CONDITIONS: {
      marginBottom: unitSpacing.half,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export interface LoginFormData {
  firstName?: string
  lastName?: string
  email: string
  password: string
}

interface LoginFormProps {
  onSubmit: (data: LoginFormData) => void
  onChange: (data: LoginFormData) => void
  loading: boolean
  submitLabel: string
  shouldValidate?: boolean
  displaySignupFields?: boolean
}

enum Terms {
  Accepted = "Accepted",
  NotAccepted = "Not Accepted",
}

const LoginForm = observer(function LoginForm({
  onSubmit,
  submitLabel,
  onChange,
  loading,
  shouldValidate = false,
  displaySignupFields = false,
}: LoginFormProps) {
  const styles = getStyles()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [firstNameError, setFirstNameError] = useState("")
  const [lastNameError, setLastNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [acceptTerms, setAcceptTerms] = useState(Terms.NotAccepted)
  const [acceptTermsError, setAcceptTermsError] = useState("")
  const [runOnChangeValidations, setRunOnChangeValidations] = useState(false)

  const onChangeFirstName = (value: string) => {
    setFirstName(value)
    validateForm({ firstName: value, lastName, email, password, acceptTerms })
    onChange({ firstName: value, lastName, email, password })
  }

  const onChangeLastName = (value: string) => {
    setLastName(value)
    validateForm({ firstName, lastName: value, email, password, acceptTerms })
    onChange({ firstName, lastName: value, email, password })
  }

  const onChangeEmail = (value: string) => {
    setEmail(value)
    validateForm({ firstName, lastName, email: value, password, acceptTerms })
    onChange({ firstName, lastName, email: value, password })
  }

  const onChangePassword = (value: string) => {
    setPassword(value)
    validateForm({ firstName, lastName, email, password: value, acceptTerms })
    onChange({ firstName, lastName, email, password: value })
  }

  const onChangeAcceptTerms = (value: Terms) => {
    setAcceptTerms(value)
    validateForm({ firstName, lastName, email, password, acceptTerms: value })
  }

  const validateForm = (
    data: {
      firstName: string
      lastName: string
      email: string
      password: string
      acceptTerms: string
    },
    options?: { runOnChange: boolean },
  ) => {
    if (!shouldValidate) {
      return true
    }

    if (options?.runOnChange) {
      setRunOnChangeValidations(true)
    }

    if (!runOnChangeValidations && !options?.runOnChange) {
      return true
    }

    let errors = validate(data, {
      firstName: {
        presence: { allowEmpty: false },
      },
      lastName: {
        presence: { allowEmpty: false },
      },
      email: {
        presence: true,
        email: {
          message: "is not a valid email",
        },
      },
      password: {
        presence: true,
        length: {
          minimum: 8,
          message: "must be at least 8 characters",
        },
      },
    })

    const termsAccepted = data.acceptTerms === Terms.Accepted
    if (!termsAccepted) {
      if (errors) {
        errors.acceptTerms = "Please accept the terms and conditions"
      } else {
        errors = { acceptTerms: "Please accept the terms and conditions" }
      }
    }

    if (!errors) {
      setFirstNameError("")
      setLastNameError("")
      setEmailError("")
      setPasswordError("")
      setAcceptTermsError("")
      return true
    } else {
      setFirstNameError(errors.firstName)
      setLastNameError(errors.lastName)
      setEmailError(errors.email)
      setPasswordError(errors.password)
      setAcceptTermsError(errors.acceptTerms)
      return false
    }
  }

  const acceptTermsLabel = (
    <Text preset={textPresets.smallSemibold} css={{ paddingTop: unitSpacing.unit }}>
      I agree to Loop&nbsp;:45's{" "}
      <Link href="/terms" passHref target="_blank" css={{ color: color.primary }}>
        Terms of Use
      </Link>
      &nbsp;and
      <br />
      <Link href="/privacy" passHref target="_blank" css={{ color: color.primary }}>
        Privacy Policy
      </Link>
    </Text>
  )

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        validateForm(
          { firstName, lastName, email, password, acceptTerms },
          { runOnChange: true },
        ) && onSubmit({ firstName, lastName, email, password })
      }}
      css={styles.LOGIN_FORM_BODY}
    >
      {displaySignupFields && (
        <>
          <TextInput
            css={styles.LOGIN_INPUT}
            placeholder="First Name"
            value={firstName}
            onChangeText={onChangeFirstName}
            disabled={loading}
            error={firstNameError}
            type="text"
          />
          <TextInput
            css={styles.LOGIN_INPUT}
            placeholder="Last Name"
            value={lastName}
            onChangeText={onChangeLastName}
            disabled={loading}
            error={lastNameError}
            type="text"
          />
        </>
      )}
      <TextInput
        css={styles.LOGIN_INPUT}
        placeholder="Email"
        value={email}
        onChangeText={onChangeEmail}
        disabled={loading}
        error={emailError}
        type="text"
      />
      <TextInput
        css={styles.LOGIN_INPUT}
        placeholder="Password"
        value={password}
        onChangeText={onChangePassword}
        disabled={loading}
        error={passwordError}
        type="password"
      />
      {displaySignupFields && (
        <div css={styles.TERMS_CONDITIONS}>
          <RadioInput
            optionsCss={{ padding: 0 }}
            options={[{ value: Terms.Accepted, label: acceptTermsLabel }]}
            value={acceptTerms}
            onChange={() => onChangeAcceptTerms(Terms.Accepted)}
          />
          {acceptTermsError && (
            <Text
              preset={textPresets.small}
              css={{ color: color.primary, marginTop: unitSpacing.half }}
            >
              {acceptTermsError}
            </Text>
          )}
        </div>
      )}
      <div css={{ marginTop: unitSpacing.unit }}>
        <AsyncButton preset="primary" loading={loading} label={submitLabel} type="submit" />
      </div>
    </form>
  )
})

export default LoginForm
