import React from "react"
import { observer } from "mobx-react-lite"
import { CSSObject } from "@emotion/react"
import { Text, textPresets } from "../../ui"
import { unitSpacing } from "../../theme/spacing"
import { color } from "pitch45-common/theme"
import { fontSizing } from "../../theme"

function getStyles() {
  const styles = {
    MARKETING_MESSAGE_CONTAINER: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      gap: unitSpacing.unit,
    },
    FORM: {
      padding: `${unitSpacing.double} ${unitSpacing.quad}`,
      borderRadius: 20,
      minWidth: "350px",
      backgroundColor: color.backgroundHighlight,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

const LoginAndSignupMarketingContent = observer(function LoginAndSignupMarketingContent() {
  const styles = getStyles()
  return (
    <div css={styles.MARKETING_MESSAGE_CONTAINER}>
      <Text preset={textPresets.hugeLight} css={{ fontSize: fontSizing.hugePlus }}>
        The Business Membership Plan
        <br /> includes The Dashboard on Loop&nbsp;:45&trade;
      </Text>
      <Text preset={textPresets.hugeLight} css={{ fontSize: fontSizing.largePlus }}>
        Invite and Manage Members,
        <br /> Design Recurring Programs,
        <br /> Review Analytics, and
        <br /> Implement Content Standards
      </Text>
    </div>
  )
})

export default LoginAndSignupMarketingContent
