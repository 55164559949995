import { useMutation, useQuery } from "@tanstack/react-query"
import { useStores } from "pitch45-common/stores/root-store-context"
import useToast from "./use-toast"
import { ToastTypes } from "../ui/toast/toast"

export default function useClaimInvitation(invitationId) {
  const { invitationStore, organizationStore } = useStores()
  const toast = useToast()

  const { data: invitation, isLoading } = useQuery(
    ["fetchInvitation", invitationId],
    () => invitationStore.fetchInvitation(invitationId!),
    {
      enabled: Boolean(invitationId),
    },
  )

  const organization = organizationStore.organizations.get(invitation?.organizationId)
  const isValid = invitation && organization

  const claimInvitationMutation = useMutation(
    () => invitationStore.claimInvitation(invitationId!),
    {
      onError: () => {
        toast.show("Unable to claim invitation", { type: ToastTypes.Error })
      },
    },
  )

  const addInvitationQueryParam = (path: string) => {
    if (invitationId) {
      return `${path}?invitationId=${invitationId}`
    } else {
      return path
    }
  }

  return {
    claimInvitationMutation,
    invitation,
    organization,
    isValid,
    isLoading,
    addInvitationQueryParam,
  }
}
