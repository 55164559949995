import React, { useEffect, useState } from "react"
import { useTheme, CSSObject } from "@emotion/react"
import { useRouter } from "next/router"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import LoginForm, { LoginFormData } from "../components/login-form/login-form"
import MainLayout from "../components/main-layout/main-layout"
import { AsyncButton, Loadable, Text } from "../ui"
import Link from "next/link"
import { textPresets } from "../ui/text/text.presets"
import { unitSpacing } from "../theme/spacing"
import { useMutation, useQuery } from "@tanstack/react-query"
import AuthCode from "react-auth-code-input"
import { color, mediaQueries } from "pitch45-common/theme"
import BackgroundImage from "../public/imgs/L45_Dashboard_Onboarding_LogIn.jpg"
import { fontSizing } from "../theme"
import TwoColumnContainer from "../components/main-layout/two-column-container"
import { useStores } from "pitch45-common/stores/root-store-context"
import { usePostLoginRedirect } from "../utils/use-post-login-redirect"
import LoginAndSignupMarketingContent from "../components/login-and-signup-marketing-content/login-and-signup-marketing-content"
import { wrapSSR } from "../utils/wrap-ssr"
import Head from "next/head"
import useClaimInvitation from "../hooks/use-claim-invitation"
import InvitationDetails from "../components/invitation-details/invitation-details"

function getStyles() {
  const styles = {
    FORM: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: `${unitSpacing.double} ${unitSpacing.quad}`,
      borderRadius: 20,
      minWidth: "350px",
      backgroundColor: color.backgroundHighlight,
      [mediaQueries.MOBILE]: {
        maxWidth: "300px",
      },
    },
    AUTH_CODE: {
      "& > div": {
        display: "flex",
        flexDirection: "row",
        gap: unitSpacing.unit,
      },
      "& input": {
        height: 40,
        width: 40,
        fontSize: 24,
        backgroundColor: color.backgroundHighlight,
        textAlign: "center",
        border: `1px solid ${color.border}`,
        color: color.text,
      },
    },
    MFA_FORM: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${unitSpacing.double} ${unitSpacing.quad}`,
      borderRadius: 20,
      minWidth: "350px",
      backgroundColor: color.backgroundHighlight,
      [mediaQueries.MOBILE]: {
        maxWidth: "300px",
      },
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

function Login() {
  const { sessionStore } = useStores()
  const theme = useTheme() as AppTheme
  const styles = getStyles()
  const router = useRouter()
  const [showLoginError, setShowLoginError] = useState(false)
  const postLoginRedirect = usePostLoginRedirect(router.query.returnUrl as string | undefined)
  const invitationId = router.query.invitationId as string | undefined

  const [loginDetails, setLoginDetails] = useState({ email: "", password: "", mfaCode: "" })

  const { claimInvitationMutation, addInvitationQueryParam } = useClaimInvitation(invitationId)

  const loginMutation = useMutation(
    ["authDomainLogin"],
    async (loginFormData: LoginFormData) => {
      await sessionStore.authDomainLogin({
        email: loginFormData.email,
        password: loginFormData.password,
        mfaCode: "",
      })
      await sessionStore.oauthAuthorize()
      return loginFormData
    },
    {
      onError() {
        setShowLoginError(true)
      },
      onSuccess: async (result) => {
        if (sessionStore.mfaEnabled) {
          setLoginDetails({
            email: result?.email || "",
            password: result?.password || "",
            mfaCode: "",
          })
          setShowMfa(true)
        } else {
          postLoginRedirect()
        }
      },
    },
  )

  const mfaLoginMutation = useMutation(
    ["authDomainLogin"],
    async (loginDetails: { email: string; password: string; mfaCode: string }) => {
      await sessionStore.authDomainLogin({
        email: loginDetails.email,
        password: loginDetails.password,
        mfaCode: loginDetails.mfaCode,
      })
    },
    {
      onError() {
        setMfaError("Invalid code")
      },
      onSuccess() {
        if (invitationId) {
          claimInvitationMutation.mutate()
        }
        postLoginRedirect()
      },
    },
  )

  // initiate the silent renew flow to check if the user is actually logged in
  const { data: ready } = useQuery(
    ["login-oauthAuthorize"], // use a unique key to prevent this query from being shared with other components
    () =>
      sessionStore
        .oauthAuthorize()
        .then(() => {
          // if auth succeeds, force the user to the dashboard
          postLoginRedirect()
          return false
        })
        .catch(() => {
          // we don't care about errors here, we didn't expect the user to be logged in anyway
          return true
        }),
    { enabled: !sessionStore.currentUser },
  )

  const loginForm = (
    <div css={styles.FORM}>
      {invitationId && <InvitationDetails invitationId={invitationId} />}
      <div css={{ textAlign: "center", marginBottom: unitSpacing.unitPlus }}>
        <Text preset={textPresets.largeLight} css={{ fontSize: fontSizing.largePlus }}>
          {invitationId ? "Continue to accept this invitation" : "Log in to account"}
        </Text>
      </div>
      <div css={{ textAlign: "center" }}>
        {showLoginError && (
          <Text
            preset={textPresets.medium}
            css={{
              display: "block",
              marginTop: unitSpacing.unit,
              color: theme.colors.primary,
            }}
          >
            Could not log in, please try again
          </Text>
        )}
      </div>
      <LoginForm
        onSubmit={loginMutation.mutate}
        onChange={() => setShowLoginError(false)}
        loading={loginMutation.isLoading || loginMutation.isSuccess}
        submitLabel="Log in"
      />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          href={addInvitationQueryParam("/login-sso")}
          passHref
          css={{ marginTop: unitSpacing.unit }}
        >
          <Text preset={textPresets.smallSemibold} css={{ color: theme.colors.primary }}>
            Log in with SSO
          </Text>
        </Link>

        <Text preset={textPresets.tinySemibold} css={{ marginTop: unitSpacing.quad }}>
          Don't have an account? &nbsp;
          <Link href={addInvitationQueryParam("/signup")} passHref>
            <Text preset={textPresets.tinySemibold} css={{ color: theme.colors.primary }}>
              Sign up
            </Text>
          </Link>
        </Text>
        <Link href="/reset-password" passHref css={{ marginTop: unitSpacing.half }}>
          <Text preset={textPresets.tinySemibold} css={{ color: theme.colors.primary }}>
            Forgot Password?
          </Text>
        </Link>
      </div>
    </div>
  )

  const [showMfa, setShowMfa] = useState(false)
  const [mfaError, setMfaError] = useState("")
  const onMfaCodeChange = (res: string) => {
    setLoginDetails({ ...loginDetails, mfaCode: res })
  }

  useEffect(() => {
    if (loginDetails.mfaCode?.length === 6) {
      submit()
    }
  }, [loginDetails.mfaCode])

  const submit = () => {
    if (!loginDetails.mfaCode) {
      setMfaError("Please enter a code")
    } else {
      setMfaError("")
      mfaLoginMutation.mutate(loginDetails)
    }
  }

  const mfaForm = (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        submit()
      }}
      css={styles.MFA_FORM}
    >
      <div css={{ textAlign: "center", marginBottom: unitSpacing.double }}>
        <Text preset={textPresets.largeLight}>
          Please enter the code from your authenticator app
        </Text>
      </div>
      <div css={styles.AUTH_CODE}>
        <AuthCode allowedCharacters="numeric" onChange={onMfaCodeChange} />
      </div>
      <div css={{ textAlign: "center" }}>
        {mfaError && (
          <Text
            preset={textPresets.medium}
            css={{
              display: "block",
              marginTop: unitSpacing.unit,
              color: theme.colors.primary /* TODO error color? */,
            }}
          >
            {mfaError}
          </Text>
        )}
      </div>
      <div css={{ marginTop: unitSpacing.doubleQuarter }}>
        <AsyncButton
          preset="primary"
          loading={mfaLoginMutation.isLoading}
          label="Submit"
          type="submit"
        />
      </div>
    </form>
  )

  const leftContent = <Loadable loading={!ready}>{showMfa ? mfaForm : loginForm}</Loadable>
  const rightContent = <LoginAndSignupMarketingContent />

  return (
    <TwoColumnContainer
      backgroundImageSrc={BackgroundImage.src}
      leftContent={leftContent}
      rightContent={rightContent}
    />
  )
}

Login.getLayout = function getLayout(page) {
  return (
    <MainLayout>
      <Head>
        <title>Loop :45 - Login</title>
      </Head>
      {page}
    </MainLayout>
  )
}

export default Login

export const getServerSideProps = wrapSSR(() => {
  return {
    props: {},
  }
})
