import React from "react"
import { observer } from "mobx-react-lite"
import { EntityAvatar, Text, textPresets } from "../../ui"
import { semanticSpacing, unitSpacing } from "../../theme/spacing"
import { useStores } from "pitch45-common/stores/root-store-context"
import { OrganizationUserRole } from "pitch45-common/models/organization-user"
import { pluralizeOnCount } from "pitch45-common/utils"
import { AssetDimensions } from "pitch45-common/models/asset"
import { CSSObject } from "@emotion/react"
import useClaimInvitation from "../../hooks/use-claim-invitation"
import { color } from "pitch45-common/theme"

function getStyles() {
  const styles = {
    CONTAINER: {
      padding: semanticSpacing.horizontal.narrow,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: unitSpacing.unit,
      width: "300px",
      textAlign: "center",
      marginBottom: unitSpacing.double,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

interface InvitationDetailsProps {
  invitationId: string
}

export default observer(function InvitationDetails({ invitationId }: InvitationDetailsProps) {
  const { assetStore, groupStore } = useStores()
  const styles = getStyles()
  const { invitation, organization, isValid } = useClaimInvitation(invitationId)

  const invitationGroupRoles: OrganizationUserRole[] =
    invitation?.roles?.filter(
      (r): r is OrganizationUserRole => r?.entityId !== invitation?.organizationId,
    ) || []

  const details = (() => {
    if (invitationGroupRoles.length === 1) {
      const firstGroup = groupStore.groups.get(invitationGroupRoles[0].entityId)
      if (firstGroup) {
        return `You have been invited to ${firstGroup.name}, ${organization?.name}`
      }
    }

    if (invitationGroupRoles.length > 1) {
      return `You have been invited to ${pluralizeOnCount(
        invitationGroupRoles.length,
        "group",
      )} in ${organization?.name}`
    }

    return `You have been invited to ${organization?.name}`
  })()

  return (
    <div css={styles.CONTAINER}>
      {isValid ? (
        <>
          <EntityAvatar
            uri={assetStore.getPublicUri(organization?.logoAssetId, AssetDimensions.SmallSquare)}
            name={organization?.name ?? ""}
            width={50}
          />
          <Text preset={textPresets.mediumSemibold}>{details}</Text>
        </>
      ) : (
        <>
          <Text preset={textPresets.medium}>
            This invitation is expired or otherwise invalid. Please contact your organization's
            administrator for assistance.
          </Text>
          <Text preset={textPresets.medium}>
            You may also reach out to{" "}
            <a href="mailto:info@loop45.com">
              <Text preset={textPresets.medium} css={{ color: color.primary }}>
                {" "}
                info@loop45.com{" "}
              </Text>
            </a>
            and we will assist you in joining your organization.
          </Text>
        </>
      )}
    </div>
  )
})
